import { Box } from '@otovo/rainbow';
import LegalLinks from 'otovoweb/src/apps/Navigation/Footer/LegalLinks';
import { useGlobalConfig } from './hooks/configContext';

const Footer = () => {
  const { BU_CONFIG } = useGlobalConfig();

  if (BU_CONFIG.slug === 'santander-br') {
    return null;
  }

  return (
    <Box as="footer" background="linear-gradient(0deg, #222222, #222222)">
      <Box maxWidth="18" mx="auto" px={{ s: '6', s3: '8', l2: '10' }} py="6">
        <LegalLinks />
      </Box>
    </Box>
  );
};

export default Footer;
