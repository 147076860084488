import { Box } from '@otovo/rainbow';
import { useTheme } from '@emotion/react';
import { Rainbow$Prop } from '../../types/rainbow';

type Props = {
  display?: Rainbow$Prop;
  fill?: Rainbow$Prop;
};

const Spacer = ({ display, fill }: Props) => {
  const { colors } = useTheme();
  return (
    <Box as="span" display={display} px="1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // @ts-ignore
        fill={colors[fill] || fill}
        width="6"
        height="6"
      >
        <circle cx="3" cy="3" r="1.5" />
      </svg>
    </Box>
  );
};

Spacer.defaultProps = {
  display: 'inline',
  fill: undefined,
};
export default Spacer;
